<!-- 帮助中心 -->
<template>
    <div class="page-hepl-cent f-wrap f-f0">
        <div class="cnet">
            <div class="cnet-info">
                <div class="info-title">帮助中心</div>
            </div>
        </div>
        <infinite-scroll :reserve="reserve" @Pullupload="loadmore" :finish="finish" :forbid="forbid">
            <artic-list :list='helpList' :isShow="true"></artic-list>
            <template slot="loading" >
                <div class="scroll-pag">
                    <div class="sp-tip"  ><i class="el-icon-loading"></i><span>正在加载</span></div>
                </div>
            </template>
            
            <div class="cir-pag">
                <list-pagination :pageCount="totalpage" @currentPageChange="handlepage" :currentPage="currpage"></list-pagination>
            </div>
            
            <template slot="nomore"  >
                <div class="scroll-pag" >
                    <div class="sp-tip"  ><span>- 暂无更多数据~ - </span></div>
                </div>
            </template>
        </infinite-scroll>
    </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
import articList from '../components/articList';
import infiniteScroll from '../components/infinite-scroll';
import listPagination from "../components/listPagination";
 //获取宽度
 var windowWidth = 0;
export default {
    components: {
        articList,
        infiniteScroll,
        listPagination
    },
    data() {
        return {
            helpList: [], //帮助中心列表
            totalpage:100000,
            currpage:1,
            //触底加载
            reserve:204,       //加载预留高度
            finish:false,
            forbid:false,       //是否禁止使用
        };
    },
    name: "helpcenter",
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.reserve = this.$store.state.footerHeight;
         window.onresize = ()=>{
           this.initUi();
        };
        this.initUi();
        this.requestGetChaosList();
    },
    methods: {
        requestGetChaosList() {
            var _that = this;
            const self = this;
            self.GoodsClassList = [];
            indexApi
                .getChaosListFn({ page: this.currpage, size: 10, class_id: 7 })
                .then((resp) => {
                    // if (res.data.data && res.data.data.length > 0) {
                    //     self.helpList = res.data.data;
                    // }
                    if(resp.status==200&&resp.data.code==1){

                        var _array = resp.data.data;
                        //是否加载全部数据
                        if(_array.length<=0){
                            _that.finish = true;
                            return;
                        }
                        //移动追加数据
                        if(windowWidth<=750){
                                _that.helpList = _that.helpList.concat(_array)
                        }else{
                            _that.helpList = _array;
                        }

                    }else{
                        this.$message.error("请求失败！")
                    }
                });
        },
        // 页面初始化
        initUi(){
            //获取宽度（是否触底加载）
            windowWidth =document.documentElement.clientWidth || document.body.clientWidth;
            if(windowWidth<=750){
                this.forbid = false
            }else{
                this.forbid =true;
            }
            console.log(this.forbid)
        },
        //pageing 当前页改变
        handlepage(page){
            console.log(page)
            this.currpage = page;
            this.requestGetChaosList();
        },
        //上拉加载回调事件
        loadmore(){
            if(this.finish) return;
            this.currpage+=1;
            this.requestGetChaosList();
        }
    },
};
</script>
<style lang="less" scoped>
.page-hepl-cent{
    .cnet {
            margin: 30px 0;
            .cnet-info {
                height: 44px;
                line-height: 44px;
                font-weight: 400;
                font-family: Microsoft YaHei;
                border-bottom: 2px solid #f02420;
                .info-title {
                    background: #f02420;
                    font-size: 22px;
                    color: #f1f1f1;
                    text-align: center;
                    display: inline-block;
                    padding: 0px 20px;
                }
            }
    }
    .scroll-pag{
        display: none;
    }

}
//移动处理
@media screen and (max-width:750px) { 
    
    .page-hepl-cent{
        border-top: 1px solid #E5E5E5;
        .cnet-title{
            font-size: 15px;
        }
        .cnet {
            margin: 18px auto 10px;
            text-align: center;
            .cnet-info {
                height: auto;
                display: inline-block;
                line-height: 1;
                font-weight: 400;
                font-family: Microsoft YaHei;
                padding-bottom: 7px;
                .info-title {
                    background: transparent;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #222222;
                    padding: 0;
                }
            }
        }
        .cir-pag{
            display: none;
        }
        .scroll-pag{
            display: block;
            text-align: center;
            padding: 15px 0;
            background-color: white;
            margin-top: 8px;
            .sp-tip{
                display: inline-block;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #959595;
                padding-left: 6px;
            }
        }
    }
}
</style>